<template>
  <div class="page bg-white" id="reorganize">
    <div class="dapfaa">
      <el-steps v-if="step != 99" :active="step" align-center>
        <el-step title="基本信息"></el-step>
        <el-step title="消毒藏品"></el-step>
        <el-step title="预览"></el-step>
      </el-steps>

      <el-form v-show="step == 0 || step == 2 || step == 99" class="foreaa" size="small" ref="inputForm" :model="inputForm"
               :rules="rules"
               label-width="100px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="消毒时间" prop="disinfectTime">
              <el-date-picker
                  v-model="inputForm.disinfectTime"
                  type="date"
                  clearable
                  :disabled="inputType"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  placeholder="选择消毒时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="消毒人员" prop="disinfectUser">
              <el-input
                  v-model="inputForm.disinfectUser"
                  clearable
                  maxlength="50"
                  :disabled="inputType"
                  style="width: 100%"
                  placeholder="请输入消毒人员(限50字)">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="消毒方式" prop="disinfectWay">
              <el-select v-model="inputForm.disinfectWay" clearable :disabled="inputType"
                         placeholder="请选择消毒方式" style="width: 100%;">
                <el-option
                    v-for="item in this.$dictUtils.getDictList('disinfection_method')"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="消毒描述" prop="disinfectDesc">
              <el-input
                  v-model="inputForm.disinfectDesc"
                  clearable
                  type="textarea"
                  :rows="3"
                  :resize="'none'"
                  maxlength="100"
                  :disabled="inputType"
                  style="width: 100%"
                  placeholder="请输入消毒描述(限100字)">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="消毒前状态" prop="disinfectFrontState">
              <el-input
                  v-model="inputForm.disinfectFrontState"
                  clearable
                  type="textarea"
                  :rows="3"
                  :resize="'none'"
                  maxlength="100"
                  :disabled="inputType"
                  style="width: 100%"
                  placeholder="描述消毒前文物的状态，如是否有损坏、污渍等(限100字)">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="消毒后状态" prop="disinfectAfterState">
              <el-input
                  v-model="inputForm.disinfectAfterState"
                  clearable
                  type="textarea"
                  :rows="3"
                  :resize="'none'"
                  maxlength="100"
                  :disabled="inputType"
                  style="width: 100%"
                  placeholder=" 描述消毒前文物的状态，如是否有残留物、是否达到预期的消毒效果等(限100字)">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="异常情况" prop="disinfectAbnormal">
              <el-input
                  v-model="inputForm.disinfectAbnormal"
                  clearable
                  type="textarea"
                  :rows="3"
                  :resize="'none'"
                  maxlength="100"
                  :disabled="inputType"
                  style="width: 100%"
                  placeholder="如果在消毒过程中发现任何异常或问题，应在此字段中记录(限100字)">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="后续处理" prop="disinfectFollow">
              <el-input
                  v-model="inputForm.disinfectFollow"
                  clearable
                  type="textarea"
                  :rows="3"
                  :resize="'none'"
                  maxlength="100"
                  :disabled="inputType"
                  style="width: 100%"
                  placeholder="如果需要对文物进行进一步的维护或处理，应在此字段中记录(限100字)">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="备注" prop="disinfectRemark">
              <el-input
                  v-model="inputForm.disinfectRemark"
                  clearable
                  type="textarea"
                  :rows="3"
                  :resize="'none'"
                  maxlength="100"
                  :disabled="inputType"
                  style="width: 100%"
                  placeholder="其它需要特别说明的事项或信息(限100字)">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div v-show="step == 2">
          <div class="flex_b_c">
            <div class="minTitle">藏品清单</div>
            <el-button size="small" v-if="butType == 1"
                       @click="exportData()">
              <i class="icon-piliangdaochu iconfont buIcon"/>
              批量导出
            </el-button>
          </div>
          <el-table
              :data="dataList"
              size="small"
              style="padding-left: 20px;margin-bottom: 20px"
              height="calc(100vh - 395px)"
              class="table"
              :row-key="'id'"
              :header-cell-style="{background:'#F9DFDF'}"
              @selection-change="selectionChangeHandle"
          >
            <el-table-column :reserve-selection="true" type="selection" width="50"/>
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="name" label="图标" width="120px">
              <template slot-scope="scope">
                <div class="flex_a_c">
                  <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                  <el-image
                      style="width: 36px; height: 36px"
                      :src="scope.row.picMasterViewUrl"
                      :fit="'cover'"
                      :preview-src-list="[scope.row.picMasterViewUrl]"
                  >
                    <div slot="error" class="image-slot">
                      <el-image
                          style="width: 36px; height: 36px"
                          :src="require('@/assets/img/default.png')"
                          :fit="'cover'">
                      </el-image>
                    </div>
                  </el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="collectionName" label="藏品名称"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="oneTypeName" label="藏品分类"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.identified == 0 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ getLeaveList(scope.row.collectionLevel) }}
              </template>
            </el-table-column>
            <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
            <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
            <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>

      <div class="foreaa" v-show="step == 1">
        <div style="margin-bottom: 10px">
          <el-button size="small" type="primary" @click="addCollData(0)" icon="el-icon-plus">添加藏品
          </el-button>
        </div>
        <div class="flex_b_c">
          <div>
            <div class="minTitle">藏品清单</div>
            <div style="margin-left: 20px">已选{{ dataList.length }} 件藏品</div>
          </div>
          <el-button icon="el-icon-delete" type="danger" size="small" @click="deleteCollData()">批量删除</el-button>
        </div>
        <el-table
            :data="dataList"
            size="small"
            ref="multipleTable"
            style="padding-left: 20px"
            height="calc(100vh - 395px)"
            class="table"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeHandle"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="step == 99" class="text_center" style="padding-top: 10px">
      <el-button size="small" @click="returnPage()">关闭</el-button>
    </div>
    <div v-else class="text_center" style="padding-top: 10px">
      <el-button size="small" @click="returnPage()">{{ butType == 1 ? '关闭' : '取消' }}</el-button>
      <el-button v-if="step == 0" size="small" type="primary" @click="nextType(0)">下一步</el-button>
      <el-button v-if="step != 0 && butType != 1" size="small" type="primary" @click="nextType(1)">上一步
      </el-button>
      <el-button v-if="step == 1" size="small" type="primary" @click="nextType(0)">预览</el-button>
      <el-button v-if="step == 2 && butType != 1" size="small" type="primary" v-noMoreClick @click="subDate()">
        {{ butType == 2 ? '保存' : '提交' }}
      </el-button>
    </div>
    <collection-list ref="collectionList" @seltDataColl="(data)=>this.dataList.push(...data)"></collection-list>
  </div>
</template>

<script>
import CollectionList from "@/views/modules/collection/business/module/collectionList.vue";

export default {
  name: "disinfectDetail",
  components: {CollectionList},
  data() {
    return {
      step: 0,
      inputForm: {
        disinfectTime: '',
        disinfectUser: '',
        disinfectWay: '',
        disinfectDesc: '',
        disinfectFrontState: '',
        disinfectAfterState: '',
        disinfectAbnormal: '',
        disinfectFollow: '',
        disinfectRemark: '',
      },
      rules: {
        disinfectTime: [
          {required: true, message: '请选择消毒时间', trigger: 'change'}
        ],
        disinfectUser: [
          {required: true, message: '请填写消毒人员', trigger: 'blur'}
        ],
        disinfectWay: [
          {required: true, message: '请选择消毒方式', trigger: 'change'}
        ],
      },
      dataList: [],
      dataListSelect: [],

      inputType: false, //输入框是否禁用
      butType: 0, //0新增

      returnPageStatus: null,//判断是否是点返回或退出按钮
      programExit: false,//是否用代码退出,不需要退出页面的提示

      leaveList: [],
    }
  },

  mounted() {
    this.butType = this.$route.query.butType
    if (this.butType == 1) {
      this.step = 2
      this.inputType = true
      this.programExit = true
    }
    this.id = this.$route.query.id
    if (this.id) {
      this.getDataDetail()
    }
    this.setPageTitle()
    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
      }
    })
  },

  methods: {
    getDataDetail() {
      this.$axios(this.api.collection.collectiondisinfectGetByid + this.id, {}, 'get').then(data => {
        if (data.status) {
          this.$nextTick(() => {
            this.inputForm = data.data
          })
          this.dataList = data.data.dataList
        }
        if (this.butType == 5) {
          this.inputType = true
          this.step = 99
        }
      })
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },

    subDate() {
      let ids = this.dataList.map(item => {
        return item.id
      })
      if (this.butType == 2) { //修改
        this.$delete(this.inputForm, 'dataList')
        this.$axios(this.api.collection.collectiondisinfectUpDate, {
          ...this.inputForm,
          dataIds: ids,
          id: this.id,
        }, 'put').then(data => {
          if (data.status) {
            this.programExit = true
            this.$message.success('修改成功')
            this.returnPage()
          } else {
            this.$message.error(data.msg)
          }
        })
      } else { //新增
        this.$axios(this.api.collection.collectiondisinfectSave, {
          ...this.inputForm,
          dataIds: ids,
        }, 'post').then(data => {
          if (data.status) {
            this.programExit = true
            this.$message.success('提交成功')
            this.returnPage()
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    },

    nextType(num) {
      if (num == 0) {
        if (this.step == 0) {
          this.$refs.inputForm.validate((valid) => {
            if (valid) {
              this.step++;
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        } else if (this.step == 1) {
          // if (this.dataList.length) {
          this.step++;
          this.inputType = true
          // } else {
          //     this.$message.error('请添加藏品!')
          //     return
          // }
        } else {
          this.step++;
          this.inputType = true
        }
      } else {
        if (this.step == 2) {
          this.inputType = false
        }
        this.step--;
      }
    },

    //添加藏品
    addCollData() {
      this.$refs.collectionList.int(this.dataList)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    //批量导出
    exportData() {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }

      ids = this.dataListSelect.map(item => {
        if (item) {
          return item.id
        }
      })
      let data = {
        ids: ids,
      }
      this.exportExcel(this.api.collection.exportCollection, data, '藏品清单', 'get')
      this.$refs.multipleTable.clearSelection()
    },

    deleteCollData() {
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      this.dataListSelect.forEach(item1 => {
        this.dataList = this.dataList.filter(item2 => {
          return item1.id != item2.id
        })
      })
      this.$refs.multipleTable.clearSelection()
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = 1
      this.$router.back()
    },

    setPageTitle() {
      let name = '新增消毒'
      if (this.butType == 1 || this.butType == 5) {
        name = '消毒详情'
      } else if (this.butType == 2) {
        name = '消毒修改'
      }
      this.$store.state.tagsView.visitedViews.forEach(item => {
        if (item.fullPath === this.$route.fullPath) {
          item.title = name
          return
        }
      })
    },
  },

  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
      sessionStorage.removeItem('listSearchCollDetail')
    }
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {})
      next();
    } else if (this.butType == 0) {
      this.$confirm('当前内容没有保存，您确定离开吗？', '提示', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //进去别的页面
        if (this.returnPageStatus){
          this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {})
        }
        next();
      }).catch(() => {
        next(false);
      });
    } else {
      next();
    }
  },
}
</script>

<style scoped>
.dapfaa{
  height: calc(100% - 35px);
  overflow-y: auto;
}

.foreaa {
  margin-top: 40px;
}

.minTitle {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
}
</style>