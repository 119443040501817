<template>
    <el-dialog
            title="藏品列表"
            :visible.sync="dialogVisible"
            width="900px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            :before-close="handleClose">
        <el-form size="small" :inline="true" ref="inputForm" class="query-form" :model="inputForm" label-width="100px">
            <el-form-item label="藏品名称" prop="collectionName">
                <el-input v-model.trim="inputForm.collectionName"
                          placeholder="请输入藏品名称(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item label="藏品编号" prop="helpNum">
                <el-input v-model.trim="inputForm.helpNum"
                          placeholder="请输入藏品编号(限50字)"
                          maxlength="50"
                          clearable>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">
                    查询
                </el-button>
                <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
            </el-form-item>
        </el-form>

        <el-table
                :data="dataList"
                size="small"
                ref="multipleTable"
                height="350px"
                class="table"
                :row-key="'id'"
                :header-cell-style="{background:'#F9DFDF'}"
                @selection-change="selectionChangeHandle"
        >
            <el-table-column :reserve-selection="true" type="selection" :selectable="canBeSelect" width="50"/>
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="name" label="图标" width="120px">
                <template slot-scope="scope">
                    <div class="flex_a_c">
                        <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                        <el-image
                            style="width: 36px; height: 36px"
                            :src="scope.row.picMasterViewUrl"
                            :fit="'cover'"
                            :preview-src-list="[scope.row.picMasterViewUrl]"
                        >
                            <div slot="error" class="image-slot">
                                <el-image
                                    style="width: 36px; height: 36px"
                                    :src="require('@/assets/img/default.png')"
                                    :fit="'cover'">
                                </el-image>
                            </div>
                        </el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="collectionName" label="藏品名称"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip></el-table-column>
            <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.identified == 0 ? '是'  : '否' }}
                </template>
            </el-table-column>
            <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ getLeaveList(scope.row.collectionLevel) }}
                </template>
            </el-table-column>
            <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
            <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
            <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
                </template>
            </el-table-column>
        </el-table>

        <div class="text_center">
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>
        <div class="text_center" style="margin-top: 20px">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="sureData()">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "collectionList",
    data() {
        return {
            disableData:[],
            dialogVisible: false,
            inputForm: {
                collectionName: '',
                helpNum: '',
            },
            dataList: [],
            dataListSelect: [],
            pageNo: 1,
            pageSize: 10,
            total: 0,
            leaveList:[],
            cStoreStateList: [
                {
                    id: 0,
                    levelName: '待入库',
                },
                {
                    id: 1,
                    levelName: '入库中',
                },
                {
                    id: 2,
                    levelName: '在库',
                },
                {
                    id: 3,
                    levelName: '待出库',
                },
                {
                    id: 4,
                    levelName: '出库中',
                },
                {
                    id: 5,
                    levelName: '已出库',
                },
            ],
        }
    },

    mounted() {
        this.$axios(this.api.collection.listLevelSelect).then(data => {
            if (data.status) {
                this.leaveList = data.data
            }
        })
    },

    methods: {
        getLeaveList(leave){
            let data = this.leaveList.filter(item=>{ return item.level == leave})
            if (data.length){
                return data[0].levelName
            }
        },

        int(data) {
            this.disableData = data
            this.dialogVisible = true
            this.$nextTick(()=>{
                // this.getDataList(1,1)
                this.resetting()
            })
        },

        sureData(){
            this.$emit('seltDataColl',this.dataListSelect)
            this.handleClose()
        },

        //重置
        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },

        // 获取元数据类型设置输入正则
        getNumType(item) {
            let regular = /[^\d]/g
            if (item.archivesBasicTypeCode == "double") {
                regular = /[^\d.]/g
            }
            this.$set(this.inputForm, item.code, this.inputForm[item.code].replace(regular, ''))
        },

        //获取列表数据
        getDataList(type, dividePage) {
            this.$refs['inputForm'].validate((valid) => {
                if (valid) {
                    if (type == 1) {
                        this.pageNo = 1
                    }
                    if (dividePage == 1) {
                        this.$refs.multipleTable.clearSelection()
                    }
                    this.loading = true
                    let fieldOrList = [
                        {
                            "archivesBasicTypeMethodCode": "=",
                            "key": "entryState",
                            "theKey": "",
                            "value": "1"
                        }, {
                            "archivesBasicTypeMethodCode": "=",
                            "key": "entryState",
                            "theKey": "",
                            "value": "2"
                        }
                    ]
                    let fieldList = []
                    fieldList.push(
                        {
                            "archivesBasicTypeMethodCode": "=",
                            "key": "processState",
                            "theKey": "",
                            "value": "3"
                        }
                    )
                    let fieldWhereList = []
                    // if (this.inputForm.helpNum) {
                    //     fieldOrList.push(
                    //         {
                    //             archivesBasicTypeMethodCode: "%",
                    //             archivesBasicTypeCode:'text',
                    //             key: 'helpNum',
                    //             value: this.inputForm.helpNum
                    //         },
                    //         {
                    //             archivesBasicTypeMethodCode: "%",
                    //             archivesBasicTypeCode:'text',
                    //             key: 'generalNum',
                    //             value: this.inputForm.helpNum
                    //         },
                    //     )
                    // }
                    if (this.inputForm.collectionName) {
                        fieldWhereList.push(
                            {
                                archivesBasicTypeMethodCode: "%",
                                archivesBasicTypeCode:'text',
                                key: 'collectionName',
                                value: this.inputForm.collectionName
                            },
                        )
                    }
                    this.searchRecord = {
                        fieldList: fieldList,
                        fieldOrList:fieldOrList,
                        fieldWhereList:fieldWhereList,
                        current: this.pageNo,
                        size: this.pageSize,
                        number: this.inputForm.helpNum,
                    }
                    this.$axios(this.api.collection.queryElasticsearchTemplatePage, this.searchRecord, 'post').then((res) => {
                        if (res.status) {
                            this.dataList = res.data.records
                            this.$nextTick(() => {
                                this.$refs.multipleTable.doLayout()
                            })
                            this.total = parseInt(res.data.total)
                            if (this.dataList.length == 0 && this.pageNo > 1) {
                                this.pageNo--
                                this.getDataList()
                            }
                        } else {
                            this.$message.error('查询失败');
                        }
                        this.loading = false
                    })
                }
            })
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.pageNo = val;
            this.getDataList('', 2);
        },

        handleClose() {
            this.dataListSelect = []
            this.dialogVisible = false
        },

        // 设置当前行是否禁用【可以选择】 参数row 传入当前行数据
        canBeSelect(row){
            let reapet = this.disableData.filter(item=>{return item.id == row.id})
            if (!reapet.length) {
                return true; // 返回true 可以选择
            } else {
                return false; // 返回false 禁止选择
            }
        },
    }
}
</script>

<style scoped>

</style>